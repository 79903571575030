import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateRev } from '../API/document';
import { FormButton, ModalComponent } from '../component';
import { Container, Form, Stack,Spinner } from 'react-bootstrap';

import SuccessMessage from '../component/messagecomponent/successMessage';

const EditXls = () => {
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState({
    successMessage: '',
    errorMessage: ''
  });
  const [relod, setRelod] = useState(false);
  const navigate = useNavigate();
  const { path, Gid, docid } = location.state || {};
  const [error, setError] = useState('');
  const [desc, setDesc] = useState('');
  const [sucess,setSuccess]=useState('');
  const [modal2, setModal2] = useState(false);
  const handleChange = (e) => {
    console.log("ggggg",e.target.value);
    setError('');
    setDesc(e.target.value);
  };








  const updateFile = async () => {
    console.log("gggg",desc);
    
    if (desc === '') {
      setError("Change Log is required");
    }else if (desc === null) {
      setError("Change Log is required");
    } else {
      const data = {
        documetId: docid,
        id: Gid,
        changedBy: localStorage.getItem("userName"),
        logs: desc
      };

      try {
        setLoad(true);
        const result = await updateRev(data);
        setLoad(false);
        console.log("nn", result);
        // setLoad(false);
        if (result.status === 201) {
          closeModel2();
          setSuccess("Document updated Successfully");
          setTimeout(() => {
            setSuccess('')
          }, 4000);
          setDesc('');
          // setModal2(false);
          // setRelod(true);
       
        } else {
          setLoad(false);
          if(result?.response?.data?.message === "old version"){
            setError("Document not Updated");
        }
        }
        
      } catch (err) {
        setLoad(false);
        setRelod(false);
        console.log("mmmm", err);
        openModel2(false); // Open modal if update throws error
      }
    }
  };



  const getData = () => {
    // Your data fetching logic goes here
    console.log('Fetching data...');
  };
  
  useEffect(() => {
    getData(); // Call getData when the component mounts
  
    // Event listener for beforeunload
    const handleBeforeUnload = async () => {
      console.log('Component is about to be unloaded. Cleaning up...');
      await AddRev();
      // Additional cleanup logic if needed
    };
  
    // Attach the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    // Cleanup function to be executed when the component is unmounted
    return () => {
      console.log('Component is unmounted. Cleaning up...');
      // Remove the beforeunload event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // Additional cleanup logic if needed
      AddRev(); 
    };
  }, []); 





  const AddRev = async () => {
   
      const data = {
        documetId: docid,
        id: Gid,
        changedBy: localStorage.getItem("userName"),
        desc: desc
      };

      try {
        setLoad(true);
        const result = await updateRev(data);
        console.log("nn", result);
        setLoad(false);
        if (result.status === 201) {
          setMessage({
            successMessage: "File Replaced Successfully"
          });
          setTimeout(() => {
            setMessage({
              successMessage: ""
            });
          }, 4000);
          setDesc('');
          setRelod(true); // Set relod to true before reloading
          window.location.reload(); // Reload the page directly 
        } else {
          openModel2(); // Open modal if update fails
        }
      } catch (err) {
        setRelod(false);
        console.log("mmmm", err);
        openModel2(); // Open modal if update throws error
      }
    
  };


 

  const openModel2 = () => {
    // event.preventDefault();
    setModal2(true);
  };

  const closeModel2 = () => {
    setError('');
    setModal2(false);
  };

  // const handleYes = () => {
  //   updateFile();
  // };

  const handleNo = () => {
    setError('');
    closeModel2();
  };

  function body2() {
    return <>
      <Container>
        <div style={{ marginLeft: "10px" }}>
          <Form.Label>
            Add Log <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Write Description here"
            label="File Name"
            style={{ height: '100px', width: "95%" }}
            value={desc} name='desc' onChange={handleChange}
          />
          {error === "Change Log is required" ? <p style={{ color: "red" }}>{error}</p> : null}

        </div>

      </Container>
    </>
  }

  function footer2() {
    return <>
      <Stack direction="horizontal" gap={2}>
        <FormButton onClick={handleNo}>Cancel</FormButton>
        <FormButton onClick={updateFile}>Submit</FormButton>
      </Stack>
    </>
  }

  function header2() {
    return <>
      <Stack direction="horizontal" gap={2}>
        <div>
        <h4>Update Document</h4>
        {error === "Document not Updated" ?<p style={{ color: "red",fontSize:'18px' }}>{error}</p>: null}
        </div>
        
      </Stack>
    </>
  }

  // If relod is true and modal is not open, reload the page
  if (relod && !modal2) {
    window.location.reload();
  }

  return (
    <div>
           {load === true && (
      <div className="spinner-container">
      <div className="text-center">
      <Spinner
        animation="border"
        role="status"
        style={{
          borderWidth: '2px', // Increase border width for a thicker circle
          width: '60px',   // Set the desired width
          height: '60px',  // Set the desired height (equal to width for a circle)
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div>Loading...</div>
    </div>
    </div>
    )}
       <SuccessMessage message={sucess || ''}/>
  
    <div style={{ height: '80vh' }}>
     
      <ModalComponent
        title={header2}
        status={modal2}
        onHide={closeModel2}
        footer={footer2}
        body={body2}
        animation={true}
      />
      <Iframe
        url={path}
        width="100%"
        height="91%"
        id=""
        className=""
        display="block"
        position="relative"
      />
      {relod ? null : <FormButton onClick={openModel2}>Add Log</FormButton>}
    </div>
    </div>
  );
};

export default EditXls;













// const getData = () => {
//   // Your data fetching logic goes here
//   console.log('Fetching data...');
// };

// useEffect(() => {
//   getData(); // Call getData when the component mounts

//   // Event listener for beforeunload
//   const handleBeforeUnload = async () => {
//     console.log('Component is about to be unloaded. Cleaning up...');
//     await AddRev();
//     // Additional cleanup logic if needed
//   };

//   // Attach the beforeunload event listener
//   window.addEventListener('beforeunload', handleBeforeUnload);

//   // Cleanup function to be executed when the component is unmounted
//   return () => {
//     console.log('Component is unmounted. Cleaning up...');
//     // Remove the beforeunload event listener
//     window.removeEventListener('beforeunload', handleBeforeUnload);
//     // Additional cleanup logic if needed
//     AddRev(); 
//   };
// }, []); 

// const AddRev = async () => {
//   const data = {
//     documetId:docid,
//     id: Gid,
//     changedBy:localStorage.getItem("userName")
//   };
//   const res = await updateRev(data);
//   // Handle response or any additional logic if needed
// };