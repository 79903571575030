import apiInstance from "./baseApi";

export const addRole = async(data) => {

  try {

    const header={
      headers: {
          "authorization":localStorage.getItem("accessToken")
      }
  }
    const response = await apiInstance.post(`role/addRole`,data,header);
    return response;
  } catch (error) {
    return error;
   
  }
}

export const getRole = async() =>{
  try{
    const header ={
      headers : {
        "authorization":localStorage.getItem("accessToken")
      }
    }
    console.log(header);
 const response = await apiInstance.get('role/getRole',header);
 return response;
  }catch(error){
 return error
  }
}


                                                                             
