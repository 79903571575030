import { useRoutes } from 'react-router-dom';

// project import
import Authroute from './authRoutes';
import Pagesroute from './pageRoute';
//import { useState } from 'react';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
 
    return useRoutes([Authroute,Pagesroute])
 
  
}