
// import React from 'react';
// import { Col, Form, Pagination, Row } from 'react-bootstrap';

// const CustomPagination = ({ currentPage, totalPages, onPageChange,status,ItemsPage,ItemsPerPages}) => {
//   const [isMobile, setIsMobile] = useState(window.matchMedia("(min-width: 600px)").matches);
//   const handlePageChange = (newPage) => {
//     onPageChange(newPage);
//  };

//  const handleItemsPerPageChange = (e) => {
//   ItemsPage(Number(e.target.value));
  
// };


//  const renderPageNumbers = () => {
//     const pages = [];
//     // Calculate the start and end of the range
//     let startPage = Math.max(1, currentPage - 1);
//     let endPage = Math.min(totalPages, currentPage + 1);

//     // Adjust the range if it's too close to the start or end
//     if (endPage - startPage < 3) {
//       if (startPage === 1) {
//         endPage = Math.min(3, totalPages);
//       } else if (endPage === totalPages) {
//         startPage = Math.max(totalPages - 3, 1);
//       }
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(
//         <Pagination.Item
//           key={i}
//           active={i === currentPage}
//           onClick={() => handlePageChange(i)}
//           style={{ backgroundColor: i === currentPage ? '#000F3F' : 'transparent', color: i === currentPage ? '#FFFFFF' : '#000F3F' }}
//         >
//           {i}
//         </Pagination.Item>
//       );
//     }
//     return pages;
//  };

//  return (
//   <div style={{width:'100%'}}>
//     <Pagination>
//       <Row style={{width:'100%'}}>
//         <Col md={12} lg={6} xl={6}>
//         <div style={{display:'flex',flexDirection:'row'}}>
//         <Pagination.First
//         onClick={() => handlePageChange(1)}
//         disabled={currentPage === 1}
//         style={{ color: '#FFFFFF' }}
//       />
//       <Pagination.Prev
//         onClick={() => handlePageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         style={{ color: '#FFFFFF' }}
//       />
//       {renderPageNumbers()}
//       <Pagination.Next
//         onClick={() => handlePageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         style={{ color: '#FFFFFF' }}
//       />
//       <Pagination.Last
//         onClick={() => handlePageChange(totalPages)}
//         disabled={currentPage === totalPages}
//         style={{ color: '#FFFFFF' }}
//       />
//         </div>
//         </Col>
//         <Col md={6} lg={5} xl={5} className="pagination-total" style={{ fontWeight: 'bold' }}>
//         {status == true ? 
//   <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:isMobile ? 'flex-end' : 'flex-start'}}>
//     <div>
//     <Form.Group controlId="itemsPerPage" className="me-3" style={{width:'100%',display:'flex',flexDirection:'row'}}>
//       <Form.Label className="me-2">Items per page:</Form.Label>
//       <Form.Select
//         value={ItemsPerPages}
//         onChange={handleItemsPerPageChange}
//         className="w-auto"
//       >
//         <option value={10}>10</option>
//         <option value={20}>20</option>
//         <option value={30}>30</option>
//         <option value={40}>40</option>
//         <option value={50}>50</option>
//       </Form.Select>
//     </Form.Group>
//     </div>
//     <div>
//     <span>Total Pages: {totalPages}</span>
//     </div>
//   </div>
//   :
//   <span>Total Pages: {totalPages}</span>
// }
// </Col>

//       </Row>
      
      
//     </Pagination>
//     </div>
//  );
// };

// export default CustomPagination;




// // import React from 'react';
// // import { Pagination } from 'react-bootstrap';

// // const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
// //   const handlePageChange = (newPage) => {
// //     onPageChange(newPage);
// //   };

// //   const renderPageNumbers = () => {
// //     const pages = [];
// //     for (let i = 1; i <= Math.min(totalPages, 5); i++) {
// //       pages.push(
// //         <Pagination.Item
// //           key={i}
// //           active={i === currentPage}
// //           onClick={() => handlePageChange(i)}
// //           style={{ backgroundColor: i === currentPage ? '#000F3F' : 'transparent', color: i === currentPage ? '#FFFFFF' : '#000F3F' }}
// //         >
// //           {i}
// //         </Pagination.Item>
// //       );
// //     }
// //     return pages;
// //   };

// //   return (
// //     <Pagination>
// //       <Pagination.First
// //         onClick={() => handlePageChange(1)}
// //         disabled={currentPage === 1}
// //         style={{ color: '#FFFFFF' }}
// //       />
// //       <Pagination.Prev
// //         onClick={() => handlePageChange(currentPage - 1)}
// //         disabled={currentPage === 1}
// //         style={{ color: '#FFFFFF' }}
// //       />
// //       {renderPageNumbers()}
// //       <Pagination.Next
// //         onClick={() => handlePageChange(currentPage + 1)}
// //         disabled={currentPage === totalPages}
// //         style={{ color: '#FFFFFF' }}
// //       />
// //       <Pagination.Last
// //         onClick={() => handlePageChange(totalPages)}
// //         disabled={currentPage === totalPages}
// //         style={{ color: '#FFFFFF' }}
// //       />
// //       <span className="pagination-total" style={{fontWeight:'bold',marginLeft:'3px'}}>
// //         Total Pages: {totalPages}
// //       </span>
// //     </Pagination>
// //   );
// // };

// // export default CustomPagination;


import React, { useState, useEffect } from 'react';
import { Col, Form, Pagination, Row } from 'react-bootstrap';

const CustomPagination = ({ currentPage, totalPages, onPageChange, status, ItemsPage, ItemsPerPages }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 600px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = (e) => setIsMobile(e.matches);
    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    onPageChange(newPage);
  };

  const handleItemsPerPageChange = (e) => {
    ItemsPage(Number(e.target.value));
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    if (endPage - startPage < 3) {
      if (startPage === 1) {
        endPage = Math.min(3, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 3, 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
          style={i === currentPage ? { backgroundColor: '#000F3F', color: '#FFFFFF' } : { backgroundColor: 'transparent', color: '#000F3F' }}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pages;
  };

  return (
    <div style={{ width: '100%' }}>
      <Pagination>
        <Row style={{ width: '100%' }}>
          <Col md={12} lg={6} xl={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
                style={{ color: '#FFFFFF' }}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{ color: '#FFFFFF' }}
              />
              {renderPageNumbers()}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{ color: '#FFFFFF' }}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
                style={{ color: '#FFFFFF' }}
              />
            </div>
          </Col>
          <Col md={6} lg={6} xl={6} className="pagination-total" style={{ fontWeight: 'bold' }}>
            {status ? (
             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: isMobile ? 'flex-end' : 'flex-start' }}>
             <Form.Group controlId="itemsPerPage" className="me-3" style={{ display: 'flex', alignItems: 'center' }}>
               <Form.Label className="me-2">Items per page:</Form.Label>
               <Form.Select value={ItemsPerPages} onChange={handleItemsPerPageChange} className="w-auto">
                 <option value={10}>10</option>
                 <option value={20}>20</option>
                 <option value={30}>30</option>
                 <option value={40}>40</option>
                 <option value={50}>50</option>
               </Form.Select>
             </Form.Group>
             <span>Total Pages: {totalPages}</span>
           </div>
            ) : (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: isMobile ? 'flex-end' : 'flex-start' }}>
              <span>Total Pages: {totalPages}</span>
              </div>
            )}
          </Col>
        </Row>
      </Pagination>
    </div>
  );
};

export default CustomPagination;

