import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { MoreOutlined } from '@ant-design/icons';
import '../../assets/MenuItem.css';
// import { useNavigate } from 'react-router-dom';

type MenuItem = {
  label: string;
  onClick: () => void; // Individual onClick function for each menu item
};

type Props = {
  menuItems: MenuItem[];
  data: string;
};

const CustomDropdownButton = (props: Props) => {
  // const navigate = useNavigate();

  const handleMenuItemPress = (menuItem: MenuItem) => {
    // Call the respective onClick function for the clicked menu item
    menuItem.onClick();
  };

  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={<MoreOutlined style={{ color: 'black', backgroundColor: 'transparent' }} />}
      className="custom-dropdown-button dropdown"
    >
      {props.menuItems.map((menuItem, index) => (
        <Dropdown.Item key={index} onClick={() => handleMenuItemPress(menuItem)}>
          {menuItem.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default CustomDropdownButton;
