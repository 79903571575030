import React from 'react';
import Form from 'react-bootstrap/Form';

type FormCheckProps = {
  type: string;
  label: string;
};

const CustomFormCheck: React.FC<FormCheckProps> = ({ type, label,onChange,checked }) => (
 <div className='container' >
 <Form.Check
    type={type}
    id={type}
    label={label}
    onChange={onChange}
    checked={checked}
    className="form-check-inline" 
  />
 </div>
 
);

export default CustomFormCheck;
