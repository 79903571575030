



// import React, { useState } from "react";
// import { Margin, usePDF } from "react-to-pdf";
// import { Button, Image } from "react-bootstrap";
// import logo from '../../assets/images/qmii-new.png';

// const Package = () => {
//     const [ss,setss]=useState('');
//     const { toPDF, targetRef } = usePDF({
//         method: "save",
//         filename: "multipage-example.pdf",
//         page: {
//           margin: {
//             top: 10,    
//             right: 20,  
//             bottom: 10,
//             left: 20,   // Specify the left margin in units
//           },
//         },
//       });
      
//   return (
//     <div >
        
//       <Button onClick={toPDF}>Download PDF</Button>

// <div >
//       <div ref={targetRef} >
//       <div >
//             <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
//           <Image src={logo} height={'120px'}/>
//           <div style={{marginTop: '60px',
//     paddingLeft: '5px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//   <p style={{ fontSize: '22px', color: 'grey', fontWeight: '700px', marginBottom: '0' }}>
//     <span style={{ fontSize: '30px' }}>Q</span>UALITY <span style={{ fontSize: '30px' }}>M</span>ANAGEMENT <span style={{ fontSize: '30px' }}>I</span>NTERNATIONAL, INC.
//   </p>

//   <p style={{ fontSize: '22px', color: 'grey', fontWeight: '500px', letterSpacing: '2px', marginTop: '0' }}>www.aworldofquality.com</p>
// </div>
//           </div>
//           <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'3%'}}>
//             <p style={{fontSize: '22px', color: 'black', fontWeight: 'bold'}}>COURSE TITLE</p>
//             <p style={{fontSize: '22px', color: 'black', fontWeight: 'bold'}}>COURSE MATERIAL PACKING INSTRUCTIONS AND CHECKLIST</p>
//           </div>
//           <div>
//             <p style={{fontSize:'20px',color:'black',marginBottom:'10px'}}>The printed material includes (3) elements:</p>
//               <ul>
//                 <li style={{fontSize:'20px',color:'black'}}>Exam</li>
//                 <li style={{fontSize:'20px',color:'black'}}>Student Manual</li>
//                 <li style={{fontSize:'20px',color:'black'}}>Tutor Pack</li>
//               </ul>
//               <p style={{fontSize:'20px',color:'black'}}>This document describes all printed materials, in order of assembly and with detailed instructions regarding printing. Authorized printer personnel complete and sign this form while packing the materials ordered. Include completed form with shipped materials for verification upon receipt by Lead Tutor.</p>
//               <p style={{fontSize:'20px',color:'black'}}>Each section contains checkboxes to indicate completion (printer - upon packing materials) and
// verification (QMII Lead Tutor - upon receipt on first day of class to be faxed to QMII within 24
// hours). Indicate successful completion and verification with a checkmark; indicate when requirements
// are not met by placing an "O" in the checkbox. If requirements cannot be met, notify QMII before
// shipping order for corrective action.</p>
// <p style={{fontSize:'20px',color:'black',marginTop:'15px'}}>ALL MATERIALS ARE TO BE PRINTED ON # 24 or 60 STOCK PAPER UNLESS OTHERWISE NOTED.</p>
// <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//   <p style={{fontSize:'20px',color:'black'}}>JOB NUMBER (from Print Order) : </p>
//   <div style={{ borderBottom: '1px solid black', width: '15%', flex: '0 0 auto', margin: '0 10px' }}></div>
// </div>


// <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{fontSize:'20px',color:'black'}}>NUMBER OF STUDENTS: (from Print Order) : </p>
// <div style={{ borderBottom: '1px solid black', width: '15%', flex: '0 0 auto', margin: '0 10px' }}></div>
// </div>
// <p style={{fontSize:'20px',color:'black',marginTop:'15px'}}>CODES: Single Sided (SS), Double Sided (DS), 3-hole punch (3H), Stapled (ST)</p>
// <p style={{fontSize:'20px',color:'black',fontWeight:'bold'}}>I. EXAM Pack</p>
// <p style={{fontSize:'16px',color:'black',fontWeight:'bold',marginTop:'15px'}}>Note: Exams need to be packed in a sealed envelope marked "confidential, for tutor use only"</p>
// <p style={{fontSize:'20px',color:'black'}}><span style={{fontWeight:'bold'}}>II. STUDENT MANUAL:</span> list includes names of tabs and documents corresponding to each tab, in the
// order of assembly. Quantity: 1 manual per student</p>
// <p style={{fontSize:'20px',color:'black'}}><span style={{fontWeight:'bold'}}>III. The Tutor Pack:</span> Please pack in the order this list is written (First item in bottom of pack). Group
// handouts by type (not by student).</p>
// <p style={{fontSize:'20px',color:'black'}}>Signature below indicates all requirements have been fulfilled:</p>
// <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'space-between' }}>
//     <div>
//     <div style={{ borderTop: '1px solid black', marginTop: '10px',marginLeft:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Authorized Printer Signature</p>
//   </div>
//   <div style={{ borderTop: '1px solid black', marginTop: '10px',marginLeft:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Authorized Printer Signature</p>
//   </div>
//     </div>
//   <div>
//   <div style={{ borderTop: '1px solid black', marginTop: '10px',marginRight:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Date</p>
//   </div>
//   <div style={{ borderTop: '1px solid black', marginTop: '10px',marginLeft:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Date</p>
//   </div>
//   </div>
 
// </div>

//           </div>
         
//         </div>
//       </div>
//       </div>
   
//     </div>
//   );
// };

// export default Package;


// import React from "react";
// import { page,View, p, Document, StyleSheet,Image,pDFDownloadLink } from "@react-pdf/renderer";
// import logo from '../../assets/images/qmii-new.png';
// const MyDocument = () => (
//   <Document>
//     <page size="A4">
//     <View >
//             <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
//           <Image src={logo} height={'120px'}/>
//           <View style={{marginTop: '60px',
//     paddingLeft: '5px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//   <p style={{ fontSize: '22px', color: 'grey', fontWeight: '700px', marginBottom: '0' }}>
//     <span style={{ fontSize: '30px' }}>Q</span>UALITY <span style={{ fontSize: '30px' }}>M</span>ANAGEMENT <span style={{ fontSize: '30px' }}>I</span>NTERNATIONAL, INC.
//   </p>

//   <p style={{ fontSize: '22px', color: 'grey', fontWeight: '500px', letterSpacing: '2px', marginTop: '0' }}>www.aworldofquality.com</p>
// </View>
//           </View>
//           <View style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'3%'}}>
//             <p style={{fontSize: '22px', color: 'black', fontWeight: 'bold'}}>COURSE TITLE</p>
//             <p style={{fontSize: '22px', color: 'black', fontWeight: 'bold'}}>COURSE MATERIAL pACKING INSTRUCTIONS AND CHECKLIST</p>
//           </View>
//           <View>
//             <p style={{fontSize:'20px',color:'black',marginBottom:'10px'}}>The printed material includes (3) elements:</p>
//               <ul>
//                 <li style={{fontSize:'20px',color:'black'}}>Exam</li>
//                 <li style={{fontSize:'20px',color:'black'}}>Student Manual</li>
//                 <li style={{fontSize:'20px',color:'black'}}>Tutor pack</li>
//               </ul>
//               <p style={{fontSize:'20px',color:'black'}}>This document describes all printed materials, in order of assembly and with detailed instructions regarding printing. Authorized printer personnel complete and sign this form while packing the materials ordered. Include completed form with shipped materials for verification upon receipt by Lead Tutor.</p>
//               <p style={{fontSize:'20px',color:'black'}}>Each section contains checkboxes to indicate completion (printer - upon packing materials) and
// verification (QMII Lead Tutor - upon receipt on first day of class to be faxed to QMII within 24
// hours). Indicate successful completion and verification with a checkmark; indicate when requirements
// are not met by placing an "O" in the checkbox. If requirements cannot be met, notify QMII before
// shipping order for corrective action.</p>
// <p style={{fontSize:'20px',color:'black',marginTop:'15px'}}>ALL MATERIALS ARE TO BE pRINTED ON # 24 or 60 STOCK pApER UNLESS OTHERWISE NOTED.</p>
// <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//   <p style={{fontSize:'20px',color:'black'}}>JOB NUMBER (from print Order) : </p>
//   <View style={{ borderBottom: '1px solid black', width: '15%', flex: '0 0 auto', margin: '0 10px' }}></View>
// </View>


// <View  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{fontSize:'20px',color:'black'}}>NUMBER OF STUDENTS: (from print Order) : </p>
// <View style={{ borderBottom: '1px solid black', width: '15%', flex: '0 0 auto', margin: '0 10px' }}></View>
// </View>
// <p style={{fontSize:'20px',color:'black',marginTop:'15px'}}>CODES: Single Sided (SS), Double Sided (DS), 3-hole punch (3H), Stapled (ST)</p>
// <p style={{fontSize:'20px',color:'black',fontWeight:'bold'}}>I. EXAM pack</p>
// <p style={{fontSize:'16px',color:'black',fontWeight:'bold',marginTop:'15px'}}>Note: Exams need to be packed in a sealed envelope marked "confidential, for tutor use only"</p>
// <p style={{fontSize:'20px',color:'black'}}><span style={{fontWeight:'bold'}}>II. STUDENT MANUAL:</span> list includes names of tabs and documents corresponding to each tab, in the
// order of assembly. Quantity: 1 manual per student</p>
// <p style={{fontSize:'20px',color:'black'}}><span style={{fontWeight:'bold'}}>III. The Tutor pack:</span> please pack in the order this list is written (First item in bottom of pack). Group
// handouts by type (not by student).</p>
// <p style={{fontSize:'20px',color:'black'}}>Signature below indicates all requirements have been fulfilled:</p>
// <View style={{ display: 'flex', flexDirection: 'row',justifyContent:'space-between' }}>
//     <View>
//     <View style={{ borderTop: '1px solid black', marginTop: '10px',marginLeft:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Authorized printer Signature</p>
//   </View>
//   <View style={{ borderTop: '1px solid black', marginTop: '10px',marginLeft:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Authorized printer Signature</p>
//   </View>
//     </View>
//   <View>
//   <View style={{ borderTop: '1px solid black', marginTop: '10px',marginRight:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Date</p>
//   </View>
//   <View style={{ borderTop: '1px solid black', marginTop: '10px',marginLeft:'10%',whiteSpace: 'nowrap'}}>
//     <p style={{fontSize:'20px',color:'black'}}>Date</p>
//   </View>
//   </View>
 
// </View>

//           </View>
         
//         </View>
//     </page>
//   </Document>
// );

// const Package = () => {
//   return (
//     <View>
//       <pDFDownloadLink document={<MyDocument />} fileName="example.pdf">
//         {({ blob, url, loading, error }) =>
//           loading ? "Loading document..." : "Download pDF"
//         }
//       </pDFDownloadLink>
//     </View>
//   );
// };
// export default Package;


import React, { useEffect, useState } from "react";
import { Page, Text, Document, StyleSheet, PDFDownloadLink, View, Image} from "@react-pdf/renderer";
import logo from '../../assets/images/qmii-new.png';
import { FormButton } from "../../component";
import { getAllModule } from "../../API/moduleApi";
import { CheckSquareOutlined } from "@ant-design/icons";
import square from '../../assets/images/check-box.png';
import box from '../../assets/images/square.png';
import { getCoursList } from "../../API/courseApi";


const styles = StyleSheet.create({
  container: {
    marginTop:'2%',
    marginLeft:'5%',
    marginRight:'5%',
    marginBottom:'2%'
  },
  header:{
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logo: {
    height: '120px',
  },
  fir:{
    fontSize: '24px',
  },
  p: {
    fontSize: '16px',
    color: 'grey',
    fontWeight: '40px',
    marginBottom: '0',
  },
  titlehead:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    marginTop:'3%'
  },
 
  sideHead :{ 
  fontSize:'20px',
  color:'black',
  marginBottom:'10px'
  },
  pack:{
    fontSize:'12px',
    color:'black',
    fontWeight:'900px',
    marginBottom:'10px'
  },

  listContainer: {
    flexDirection: 'column',
    fontSize:10,
    color: 'black',
    lineHeight:'2px'
  },
  listItem: {
    marginBottom: 5, 
  },

  text: {
    fontSize: 10,
    color: 'black',
    marginBottom: 10,
    lineHeight:'2px' 
  },

  text: {
    fontSize: 10,
    color: 'black',
    marginTop: 15,
    marginBottom: 10,
    lineHeight:'5px'
    
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 10,
    color: 'black',
    lineHeight:'2px'
  },
  underline: {
    borderBottom: '1px solid black',
    width: '15%',
    flex: '0 0 auto',
    margin: '0 10px',
  },
  PDFViewer:{width: '100%', 
  height: '100vh'
 },

 numberstu: {
  flexDirection: 'row',
  alignItems: 'center',
},
labelText: {
  fontSize: 10,
  color: 'black',
  lineHeight:'2px'
},
underline: {
  borderBottom: '1px solid black',
  width: '15%',
  flex: '0 0 auto',
  margin: '0 10px',
},
PDFViewer :{
 width: '100%',
 height: '100vh' 
},
text: {
  fontSize: 10,
  color: 'black',
  marginTop: 15,
  lineHeight:'2px'
},
boldText: {
  fontSize: 10,
  color: 'black',
  fontWeight: 'bold',
  marginTop: 15,
  lineHeight:'2px'
},
subText: {
  fontSize: 10,
  color: 'black',
  fontWeight: 'bold',
  marginTop: 15,
  lineHeight:'2px'
},
PDFViewer:{ 
  width: '100%', 
height: '100vh' 
},

last: {
  fontSize: 10,
  color: 'black',
  lineHeight:'2px'
},
signatureContainer: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  lineHeight:'5px'
},
signatureBox: {
  borderTop: '1px solid black',
  marginTop: '10px',
  whiteSpace: 'nowrap',

},
signatureText: {
  fontSize: 10,
  color: 'black',
  lineHeight:'2px'
  
},
dateBox: {
  borderTop: '1px solid black',
  marginTop: '10px',
  marginLeft: '10%',
  whiteSpace: 'nowrap',
},
PDFViewer:{
width: '100%',
height: '100vh'
},
text: {
  fontSize: 10,
  color: 'black',
  marginBottom: 10,
  lineHeight:'2px'
},
qal:{
  marginTop: '60px',
     paddingLeft: '5px',
     display:'flex',
     flexDirection:'column',
     justifyContent:'center',
     alignItems:'center'
     
},

table: {
  display: "table",
  width: "auto",
  borderStyle: "solid",
  borderWidth: 1,
  borderRightWidth: 0,
  borderBottomWidth: 0,
  marginBottom:'10px'
},
tableRow: {
  flexDirection: "row",
  borderBottomWidth: 1,
  borderBottomColor: "black", // Add a border at the bottom of each row
  alignItems: "center",
},
tableCell: {
  flex: 1,
  fontSize: 10,
  padding: 5,
  borderStyle: "solid",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  textAlign: "center", // Center-align the text
},
tableHeader: {
  fontSize: 10,
  fontWeight: "bold",
  backgroundColor:'grey',
  color:'white'
},
v4: {
  flexDirection: 'column',
  paddingTop: 20,
  paddingBottom:20
},


haeder:{
marginBottom:'15px',
color:'#87CEEB',
textAlign: 'center'
},
row1: {
  width:'20%'
},
row2: {
  width:'50%'
},
row3: {
  width:'30%'
},
row4:{
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between'
},

boldText: {
  fontSize: 12,
  color: 'black',
  fontWeight: 'bold',
  marginTop: 15,
  lineHeight: '2px',
},

text: {
  fontSize: 12,
  color: '#87CEEB',
  marginTop: 5,
  marginRight: 10,
  marginBottom:'5px'
},

courseInfo: {
  fontSize: 12,
  color: 'black',
  marginTop: 5,
  marginLeft: 10,
  marginBottom:'5px'
},
courseInfo1: {
  fontSize: 12,
  color: '#87CEEB',
  marginTop: 5,
  marginLeft: 10,
  marginBottom:'5px'
},
moduleItem: {
  marginBottom:'3px',
  fontSize: 12,
  color: 'black',
  marginLeft: 20,
},
moduleItems:{
  marginBottom:'3px',
  fontSize: 12,
  color: 'black',

},
deatil:{
  display:'flex',
  flexDirection:'row',
  width:'100%',
  marginBottom:'10px'
}

});

const phoneLogo = 'https://img.icons8.com/android/96/000000/phone.png';
const ManualComponent = ({ packdatas }) => (
  <View>
    {packdatas &&   packdatas
    .filter(item => !["Class Start", "Day End", "Lunch", "Break"].includes(item?.name))
    .map((item, index) => (

      <View key={index} style={styles.deatil}>
        <View style={styles.row1}>
          <Text style={styles.courseInfo1}>{item?.name}</Text>
        </View>
        <View style={styles.row2}>
          <Text style={styles.courseInfo}>Course Info: {item?.desc}</Text>
          {item.moduleDocument && item.moduleDocument.map((module, idx) => (
            <Text key={idx} style={styles.moduleItem}>{module.fileTitle}</Text>
          ))}
        </View>
        <View style={styles.row3}>
          <View style={styles.row4}>
            <View>
              <Text style={styles.text}>SM</Text>
              {item.moduleDocument && item.moduleDocument.map((module, idx) => (
            <Text key={idx} style={styles.moduleItems}>{module.sm === true ?  <Image src={square} /> : <Image src={box} />}</Text>
          ))}
            </View>
           <View> 
           <Text style={styles.text}>TP</Text>
           {item.moduleDocument && item.moduleDocument.map((module, idx) => (
            <Text key={idx} style={styles.moduleItems}>{module.t_pk === true ? <Image src={square} /> :  <Image src={box} />}</Text>
          ))}
           </View>
           <View >
           <Text style={styles.text}>TS</Text>
           {item.moduleDocument && item.moduleDocument.map((module, idx) => (
            <Text key={idx} style={styles.moduleItems}>{module.t_sld === true ? <Image src={square} /> : <Image src={box} />}</Text>
          ))}
           </View>
        
          </View>
        </View>
      </View>
    ))}
  </View>
);


const MyDocument = ({ packdatas,name }) => (
  <Document>
    <Page size="A4" style={styles.v4}>
      <View style={styles.container}>
        <Text style={styles.haeder}>Module List By Time Table for Course: {name} <CheckSquareOutlined /> </Text>
        <ManualComponent packdatas={packdatas}/>
      </View>
    </Page>
  </Document>
);

const TimeTablePackage = ({tableLength,datas}) => {

useEffect(()=>{
  packageList()
},[])

const [packdata, setPackData] = useState([]);

// const packageList = async () => {
//   try {
//     console.log(datas,"hhhhhhhhh");
//     const ids = datas.module.map((item) => item.moduleId);
//     const newPage = "";

//     for (const id of ids) {
//       const result = await getAllModule(newPage, id);
//       const moduleDocument = result?.data?.data[0];
//       console.log(moduleDocument,"moduleDocumentvvvvvvvvvvvvvvvvvvvv");
//       setPackData((prevPackData) => [...prevPackData, moduleDocument]);
    
//     }

//     console.log("All requests completed successfully");
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };


const packageList = async () => {
  try {
    const moduleId = datas.modules.map((item) => item._id);
    const payload = {
      moduleIds: moduleId
    };
    const result = await getCoursList(payload);
    console.log("hnnnnnnnnnnnnnnnnnnn",result);
    const moduleDocumentArrays = result?.data?.data;
    console.log("hnnnnnnnnnnnnnnnnnnn",moduleDocumentArrays);
    setPackData(moduleDocumentArrays);
  } catch (error) {
    console.error("Error:", error);
  }
};

const handleDownloadSuccess = () => {
  setPackData(['']);
  packageList();
};


  return (
    <div >
      {tableLength?.length > 1 ?
     
          <PDFDownloadLink document={<MyDocument packdatas={packdata} name={datas?.course_Name} />}  fileName={`${datas?.course_Name}(Timetable).pdf`} style={{ textDecoration: 'none' }}>




           {({ blob, url, loading, error }) => (
         <FormButton onClick={handleDownloadSuccess}>
            Generate Time Table
          </FormButton>
        )}
      </PDFDownloadLink>
     
      :
      null
}
    </div>
  );
};

export default TimeTablePackage;
