import React, { useEffect, useState } from 'react';
import Login from '../screen/authScreen/login';
import { Navigate } from 'react-router-dom';
const PrivateRoute = ({ path, element  }) => {
  const [isAuthenticated, setIsAuthenticated] = useState("");
 
  useEffect(()=>{
    setIsAuthenticated(localStorage.getItem("accessToken"))
  },[])
    return(
      <>
      {
     !isAuthenticated ? (
      element
    ) : (
      <Navigate to="/document" replace={true}/> 
    )
 }
    </>
    )
  };


const Authroute = {
  path: '/',
  children: [
    { path: '', element: <PrivateRoute path={'/'} element={<Login />} /> },
  ],
}
export {PrivateRoute};
export default Authroute;
