import apiInstance from './baseApi';
export const getAllModuleName = async () => {
    try{
        const heder={
            headers: {
                "authorization":localStorage.getItem("accessToken")
            }
        }
          const response = await apiInstance.get(`course/getModulesName`,heder);
          return response;
    }catch(err){
        return err;
    }
}

export const addCourseDetails = async (data) => {
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
    try{
          const response = await apiInstance.post(`course/addCourse`,data,headers);
          return response;
    }catch(err){
    //   if(err?.response?.statusText === "Unauthorized"){
  
    //   }
        return err;
    }
}

export const getAllCourse = async (page,itemperpage,name,srt) => {
    console.log(page,srt,"hhhhh");
    try{
        const heder={
            headers: {
                "authorization":localStorage.getItem("accessToken")
            }
        }
          const response = await apiInstance.get(`course/getCourses?page=${page}&ITEMS_PER_PAGE=${itemperpage}&name=${name}&srt=${srt}`,heder);
          return response;
    }catch(err){
        return err;
    }
}

export const editCourse = async (data) => {
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
    try{
          const response = await apiInstance.patch(`course/editCourse`,data,headers);
          return response;
    }catch(err){
 
        return err;
    }
}

export const deleteCourse=async(id)=>{
  
    try{
          const response = await apiInstance.delete(`course/deleteCourse`,{
            data:{id} ,
            headers: {
              Authorization: `${localStorage.getItem('accessToken')}`,
            }
        });
          return response;
    }catch(err){
 
        return err;
    }
}


export const cretePackage = async (data) => {
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
    try{
          const response = await apiInstance.post(`course/cretePackage`,data,headers);
          return response;
    }catch(err){
    //   if(err?.response?.statusText === "Unauthorized"){
  
    //   }
        return err;
    }
}

export const getCoursList = async (data)=>{ 
   
console.log(data,"fffffffffff");
    try {
   
      const response = await apiInstance.post('course/getCoursList',
        data,
        {
        headers: {
        authorization: `${localStorage.getItem('accessToken')}`,
        }});
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
    }
  }


  export const studentPackpdf = async (data) => {
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
    try{
          const response = await apiInstance.post(`course/createStudentManual`,data,headers);
          return response;
    }catch(err){
 
        return err;
    }
}

export const createTutorSlidePdf = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/createTutorSlide`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}
export const tutorPackpdf = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/createTutorPack`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const timeTablepdf = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/createTimeTablePdf`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const packList = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/createPackageList`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const courseDownload = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/cretePackage`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const intigratedCourse = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/megedPack`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const publish = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/publishCourses`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const TimeTables = async (data,status) => {
  console.log("time tabllllllllllllllllllllllllllllllllllllllllllll");
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/TimeTable?status=${status}`,data,headers);
        return response;
  }catch(err){

      return err;
  }
}

export const ModuleList = async (data) => {
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
  try{
        const response = await apiInstance.post(`course/createModuleList`,data,headers);
        return response;
  }catch(err){
      return err;
  }
}
