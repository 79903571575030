import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ViewCoc() {
  const location = useLocation();
  const { data, name } = location.state || {};

  useEffect(() => {
    document.getElementById('myIframe').src = data;
  }, [data]);

  return (
    <div style={{height:'100vh'}}>
      <h3>{name}</h3>
      <iframe id="myIframe" width="100%" height="100%" display="block" position="relative"></iframe>

    </div>
  );
}

export default ViewCoc;
