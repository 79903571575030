import React from 'react';
import { FormLabel } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function SelectComponent({ options, label, name, value, onChange ,defaultValue,width,disabled,required}) {
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    onChange(selectedValue);
  };

  return (
    <div className="container">
      <FormLabel>
        {label}{required? <span style={{color:"red"}}>*</span>:null}
      </FormLabel>
      <Form.Select
        aria-label={label}
        name={name}
        value={value}
        yarn upgrade
        onChange={handleSelectChange} // Bind the event handler
        style={{width:width}}
        
        disabled={disabled ? disabled : false}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
     
    </div>
  );
}

export default SelectComponent;
