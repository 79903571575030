import React, { useState } from 'react';
import '../assets/Document.css';
import { Button } from 'react-bootstrap';
import { Testdc } from '../API/document';

const TestDoc = () => {
  const [base64Data, setBase64Data] = useState('');
  // const [base64Data1, setBase64Data1] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setBase64Data(event.target.files[0]);
    // if (selectedFile) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const base64String = reader.result.split(',')[1];
    //     setBase64Data(base64String);
    //   };
    //   reader.readAsDataURL(selectedFile);
    // }
  };

  // const handleFileChange1 = (event) => {
  //   setBase64Data1(event.target.files[0]);
  // }

const submit=async()=>{
  const data = new FormData();
  data.append('file', base64Data);
  // data.append('file2', base64Data1);
  
  try {
      const res = await Testdc(data);
      console.log(res);
  } catch (error) {
      console.error('Error:', error);
  }
   

}

  return (
    <div>
      <input type='file' onChange={handleFileChange} />
      <Button onClick={submit}>Submit</Button>
    </div>
  );
};

export default TestDoc;
