import React from "react";
import { InputGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InputField = (props: Props) => (
  <div className="container" style={{padding:props.padding}}>
    {props.label && (
      <Form.Label htmlFor={props.label} style={{width:props.width,height:props.height}}>
        {props.label}
      
        {/* {props.type} */}
        {props.required && <span style={{color:"red"}}>*</span>}
      </Form.Label>
    )}
    <InputGroup style={{width:props.width,height:props.height}}>
    <Form.Control {...props} />
    {props.icon && (
            <InputGroup.Text id="basic-addon1">{props.icon}</InputGroup.Text>
  )}
  </InputGroup>
  </div>
);

export default InputField;


// InputField.jsx

// import React from "react";
// import { Form, InputGroup } from 'react-bootstrap';

// type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
//   label?: string;
//   required?: boolean;
//   width?: string;
//   height?: string;
//   icon?: React.ReactNode; // Updated to accept any React node for the icon
// };

// export const InputField = (props: Props) => (
//   <div className="container">
//     {props.label && (
//       <Form.Label htmlFor={props.label} style={{ width: props.width, height: props.height }}>
//         {props.label}
//         {props.required && <span>*</span>}
//       </Form.Label>
//     )}

//     <InputGroup>
//       <Form.Control {...props} />
//       {props.icon && (
//             <InputGroup.Text id="basic-addon1">{props.icon}</InputGroup.Text>
//   )}
//     </InputGroup>
//   </div>
// );
// export default InputField;