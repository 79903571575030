import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const RangeDatePicker = ({ filterDate, width }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pickerOpen, setPickerOpen] = useState(false);
  const datePickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setPickerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate('');
    }
    if (newStartDate && endDate) {
      const dateRange = newStartDate + "#" + endDate;
      filterDate(dateRange);
      setPickerOpen(false);
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    if (!startDate || new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate);
    }
    if (startDate && newEndDate) {
      const dateRange = startDate + "#" + newEndDate;
      filterDate(dateRange);
      setPickerOpen(false);
    }
  };

  return (
    <div ref={datePickerRef} style={{ width: width ? width : "100%" }}>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          min="1970-01-01"
          max={endDate ? endDate : ''}
        />
        <p>To</p>
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          min={startDate ? startDate : '1970-01-01'}
          max={new Date().toISOString().split('T')[0]}
        />
      </div>
    </div>
  );
};

RangeDatePicker.propTypes = {
  filterDate: PropTypes.func.isRequired,
  width: PropTypes.string
};

export default RangeDatePicker;
