import React from 'react';
// import { Routes, Route, BrowserRouter } from 'react-router-dom';
import ScrollTop from './component/scrollTop';
import Routes from './route/index';


const App = () => {
 
  return (
  
    <ScrollTop>
    <Routes />
  </ScrollTop>

  );
};

export default App;
