import React from "react";
import { Button } from "react-bootstrap";
import '../../assets/ButtonForm.css';
type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  width?: string; // Add a width prop
  style?: React.CSSProperties; // Add a style prop
};

export const FormButton = (props: Props) => (
  <Button
    {...props}
    // variant="outline-primary"
    className="formButton"
    style={{
      border:'1px solid lightgrey',
      borderRadius: '10px',
      width: props.width || '170px', // Use the provided width prop or default to '170px'
      ...props.style // Merge the provided style prop with the default style
    }}
  >
    {props.children}
  </Button>
);

export default FormButton;
