import React from "react";
import { CheckBoxComponent, FormButton,TableComponent, } from "../../component";
import CustomPagination from "../../functions/pagination";

function Permission() {


  const handlePageChange = (newPage) => {
    console.log('Page changed to:', newPage);
  };
  
const json = [
  {Module:"Document",Feature:"Document",},
  {Module:"Module",Feature:"Module"},
  {Module:"Course",Feature:"Course"},
{Module:"Time Table",Feature:"Time Table"},
{Module:"User Management",Feature:"User",},
{Module:"  ",Feature:"Role Create"},
{Module:"  ",Feature:"Permission"}
]


      
var columns = [
  {
    datan: 'Module',
    name: 'Module',
  },
  {
    datan: 'Feature',
    name: 'Feature',
  },
  {
    datan: 'View',
    name: 'View',
    Call: function (rowData) {
      return (
        <CheckBoxComponent
          menuItems={[
            <input type="checkbox" id="myCheckbox" name="myCheckbox" value="checkboxValue" />
          ]}
        />
      );
    },
  },
  {
    datan: 'Add',
    name: 'Add',
    Call: function (rowData) {
      return (
        <CheckBoxComponent
          menuItems={[
            <input type="checkbox" id="myCheckbox" name="myCheckbox" value="checkboxValue" />
          ]}
        />
      );
    },
  },
  {
    datan: 'Edit',
    name: 'Edit',
    Call: function (rowData) {
      return (
        <CheckBoxComponent
          menuItems={[
            <input type="checkbox" id="myCheckbox" name="myCheckbox" value="checkboxValue" />
          ]}
        />
      );
    },
  },
  {
    datan: 'Delete',
    name: 'Delete',
    Call: function (rowData) {
      return (
        <CheckBoxComponent
          menuItems={[
            <input type="checkbox" id="myCheckbox" name="myCheckbox" value="checkboxValue" />
          ]}
        />
      );
    },
  },
];



  return (
    
    <div style={{height:'5vh'}}>
      <div className="g-1" style={{ padding : "10px"}}>
        <p><h3>Assign Permission</h3></p>
        
      </div>
     
    <div>  
     
      <TableComponent
          columns={columns}
          data={json}
        />
        
      <CustomPagination currentPage={1} totalPages={50} onPageChange={handlePageChange} />
  <div style={{ display:'flex',flexDirection:'row',justifyContent:'right',marginTop:-50}}>
  <FormButton>Save</FormButton>
  </div>   
 
    </div>
    
    </div>
  );
}


// components/custom-editor.js

// import React from 'react';
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build";

// const editorConfiguration = {
//     toolbar: [
//         'heading',
//         '|',
//         'bold',
//         'italic',
//         'link',
//         'bulletedList',
//         'numberedList',
//         '|',
//         'outdent',
//         'indent',
//         '|',
//         'imageUpload',
//         'blockQuote',
//         'insertTable',
//         'mediaEmbed',
//         'undo',
//         'redo'
//     ]
// };

// function Permission( props ) {
//         return (
//             <CKEditor
//                 editor={ Editor }
//                 config={ editorConfiguration }
//                 data={ props.initialData }
//                 onChange={ (event, editor ) => {
//                     const data = editor.getData();
//                     console.log( { event, editor, data } );
//                 } }
//             />
//         )
// }




export default Permission;
