import apiInstance from "./baseApi"

export const getAllDocName = async () => {
    try{
        const heder={
            headers: {
                "authorization":localStorage.getItem("accessToken")
            }
        }
          const response = await apiInstance.get(`module/getAllDocName`,heder);
          return response;
    }catch(err){
    //   if(err?.response?.statusText === "Unauthorized"){
  
    //   }
        return err;
    }
}

export const getAllModule = async (page,id,name,srt) => {
  if(page){
    try{
      const heder={
          headers: {
              "authorization":localStorage.getItem("accessToken")
          }
      }
        const response = await apiInstance.get(`module/getAllModule?page=${page}&name=${name}&srt=${srt}`,heder);
        return response;
  }catch(err){
  //   if(err?.response?.statusText === "Unauthorized"){

  //   }
      return err;
  }

  }else{
    try{
      const heder={
          headers: {
              "authorization":localStorage.getItem("accessToken")
          }
      }
        const response = await apiInstance.get(`module/getAllModule?moduleIds=${id}`,heder);
        return response;
  }catch(err){
  //   if(err?.response?.statusText === "Unauthorized"){

  //   }
      return err;
  }
  }
  
}

export const addModule = async (data) => {
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
    try{
          const response = await apiInstance.post(`module/addModule`,data,headers);
          return response;
    }catch(err){
    //   if(err?.response?.statusText === "Unauthorized"){
  
    //   }
        return err;
    }
}

export const editModule = async (data) => {
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
    try{
          const response = await apiInstance.patch(`module/editModule`,data,headers);
          return response;
    }catch(err){
    //   if(err?.response?.statusText === "Unauthorized"){
  
    //   }
        return err;
    }
}

export const addModuleType = async (data)=>{ 
  
  try {
    const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    const response = await apiInstance.post('module/postModuleType', data, headers);
    return response;
  } catch (error) {
    return error;
  }
}

export const getModuleType = async ()=>{ 

  try {
    const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    const response = await apiInstance.get('module/getModuleType', headers);
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    return error;
  }
}

export const deleteMod = async (id)=>{ 
  try {
    const data = {
      id:id
    }
    const response = await apiInstance.delete(`module/deleteModule`,{
      data,
      headers: {
        Authorization: `${localStorage.getItem('accessToken')}`,
      }
  });
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    return error;
  }
}
export const deleteModAlert = async (id)=>{ 
  console.log("idid",id);
  try {
    const response = await apiInstance.get(`module/deleteModuleAlert?id=${id}`,{
      headers: {
        Authorization: `${localStorage.getItem('accessToken')}`,
      }
  });
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    return error;
  }
}
