import { useNavigate } from 'react-router-dom';
export const HandleLogout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userType");
  window.location.reload();
  useNavigate('/')
 
};


