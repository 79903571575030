import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../assets/ModuleHistory.css';



const HistoryCourse=()=>{
  const location = useLocation();
  const { histor } =location.state || {};
  console.log("histor",histor);
    const history=[{
        editorName:'uou',date:'hyrytbn',
    }]
return(
    <div>   
    <h1>History</h1>
    <div className="history-container">
      <div style={{maxHeight: 'calc(100vh - 200px)',paddingRight:"5px"}}>
        {histor?.history?.map((item, index) => (
          <div className="history-item" key={item._id}>
            <div className="editor-name">Editor: {item.editorName}</div>
            <div className="date-time">
              Date: {new Date(item.date).toLocaleDateString()}<br />
              Time: {new Date(item.date).toLocaleTimeString()}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
};


export default HistoryCourse;